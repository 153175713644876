import React, {useState, useEffect} from 'react';
// import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';
import DatabaseService from './../../services/DatabaseService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Showcase from '../layout/Showcase';
// import Row from './../../common/components/Row';
// import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
import Button from './../../common/components/Button';
import Cta from './../../common/components/Cta';

// import {LAYOUT_SM, LAYOUT_XS } from '../../config';


const HelpUsPage = ({layout}) => {
  let { lang } = useParams();
  // const [horses, setHorses] = useState([]);
  // const [horsesSearching, setHorsesSearching] = useState([]);
  const [horsesResident, setHorsesResident] = useState([]);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    // DatabaseService.getHorses({order: "random", showWeb: true}).then((response) => {
    //   if(response.success){
    //     setHorses(response.data);
    //   }else{
    //     console.log(response.error);
    //   }
    // });   
    DatabaseService.getHorses({
      status: "Residente dell'Oasi", 
      showWeb: true,
      order: "name"
    }).then((response) => {
      if(response.success){
        setHorsesResident(response.data);
      }else{
        console.log(response.error);
      }
    });
    // DatabaseService.getHorses({
    //   status: "In cerca di casa", 
    //   showWeb: true,
    //   order: "name"
    // }).then((response) => {
    //   if(response.success){
    //     setHorsesSearching(response.data);
    //   }else{
    //     console.log(response.error);
    //   }
    // });
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Sostienici" : "Support us"}
        languages={{"it": "sostienici", "en":"support-us"}}
      />
      <Header 
        uppertext={lang === "it" ? "Sostienici" : "Support us"}
        text={lang === "it" ? "L'unione fa la forza" : "Unity is strength"}
        lowertext={lang === "it" ? "Ma abbiamo bisogno dell'aiuto di tutti voi!" : "But we need the help of all of you!"}
        page="homepage"
        alt="Emotional image"
      />
      
      <Section variant="secondary-2">
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Come aiutarci</Typo>
              <Typo type="p">
                Abbiamo bisogno dell'aiuto di tutti voi!
              </Typo>
              <Typo type="p">
                I cavalli salvati vivono in gestione naturale presso la nostra Oasi o presso le strutture che garantiscano un'alta qualità della vita. 
              </Typo>
              <Typo type="p">
                Il fieno, le integrazioni, le cure, le pensioni... Tutte queste spese gravano sull'associazione ogni mese, i fondi scarseggiano mentre le richieste di aiuto dei cavalli aumentano sempre di più!
              </Typo>
            </>
          :
            <>
              <Typo type="h2">How to help us</Typo>
              <Typo type="p">
                We need the help of all of you!
              </Typo>
              <Typo type="p">
                The rescued horses live in natural management at our Oasis or in facilities that guarantee a high quality of life.
              </Typo>
              <Typo type="p">
                The hay, supplements, care, pensions... All these expenses burden the association every month, funds are scarce and the horses' requests for help increase!
              </Typo>
            </>
          }
        </Padding>
      </Section>



      <Section 
        variant="secondary-3" 
        imageDx={"/web/sections/sostienici-cinquexmille.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2">{lang === "it" ? "Dona il 5x1000" : "Donate 5x1000"}</Typo>
          {lang === "it" ? 
            <>
              <Typo type="p">
                Inserisci il nostro codice fiscale <b>93069130206</b> nel tuo 730 per donarci il tuo 5x1000.
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Come fare"
                link="/it/sostienici/cinque-x-mille"
                icon="arrow_forward"
              />
            </>
          : 
            <>
              <Typo type="p">
                Enter our tax code <b>93069130206</b> in your 730 to donate us your 5x1000.
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Come fare"
                link="/it/support-us/cinque-x-mille"
                icon="arrow_forward"
              />
            </>
          }
        </Padding>
      </Section>

      <Section 
        variant="primary-2" 
        imageSx={"/web/sections/sostienici-donazioni.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Donazioni</Typo>
              <Typo type="p">
                Con una semplice donazione puoi contribuire al sostegno dell'associazione, permettendoci di affrontare nuovi salvataggi, cure impreviste, fare lavori di manutenzione o sostituire l'attrezzatura, e qualsiasi altra cosa possa aiutarci.<br/>
                Per esempio:<br/><br/>
                <ul>
                  <li>€50: un ballone di fieno</li>
                  <li>€60: pareggio degli zoccoli di un cavallo</li>
                  <li>€30: una vaccinazione</li>
                  <li>€20: un vermifugo</li>
                </ul>
              </Typo>
              <center>
                <a href="https://donate.stripe.com/14k14SepN0oo7nO5km" target="_blank" rel="noreferrer">
                  <Button 
                    size="big" 
                    variant="light-o"
                    text="Dona ora"
                    icon="arrow_forward"
                  />
                </a>
              </center>
              <br/>
              <Typo type="p">oppure</Typo>
              <Typo type="p">
                <b>{lang === "it" ? "Bonifico Bancario" : "Bank Transfer"}</b>
                <br/>IBAN: IT74S0103058030000010190156  
                <br/>{lang === "it" ? "Intestato a" : "Transfer to"}: Associazione Equinozio Onlus
                <br/>{lang === "it" ? "Causale" : "Description"}: Donazione libera
              </Typo>
              <Typo type="p">
                <b>PayPal</b>
                <br/>Email: equinozioonlus@gmail.com  
                <br/>{lang === "it" ? "Causale" : "Description"}: Donazione libera
              </Typo>
              <Typo type="p">
                <b>Ricarica PostePay</b>
                <br/>N°: 4023 6009 3490 3560  
                <br/>{lang === "it" ? "Intestato a" : "Transfer to"}: Erica Coizzi ({lang === "it" ? "presidente" : "president"})
                <br/>{lang === "it" ? "Codice fiscale" : "Tax code"}: CZZRCE79C60E897K 
              </Typo>
            </> 
          :
            <>
              <Typo type="h2">Donations</Typo>
              <Typo type="p">
                With a simple donation you can help support the association, enabling us to tackle new rescues, unforeseen cures, do maintenance work or replace equipment, and anything else that will help us.<br/>
                For example:<br/><br/>
                <ul>
                  <li>€50: a bale of hay</li>
                  <li>€60: trimming a horse's hooves</li>
                  <li>€30: a vaccination</li>
                  <li>€20: a deworming</li>
                </ul>
              </Typo>
              <center>
                <a href="https://donate.stripe.com/14k14SepN0oo7nO5km" target="_blank" rel="noreferrer">
                  <Button 
                    size="big" 
                    variant="light-o"
                    text="Donate now"
                    icon="arrow_forward"
                  />
                </a>
              </center>
              <br/>
              <Typo type="p">otherwise</Typo>
              <Typo type="p">
                <b>{lang === "it" ? "Bonifico Bancario" : "Bank Transfer"}</b>
                <br/>IBAN: IT74S0103058030000010190156  
                <br/>{lang === "it" ? "Intestato a" : "Transfer to"}: Associazione Equinozio Onlus
                <br/>{lang === "it" ? "Causale" : "Description"}: Donazione libera
              </Typo>
              <Typo type="p">
                <b>PayPal</b>
                <br/>Email: equinozioonlus@gmail.com  
                <br/>{lang === "it" ? "Causale" : "Description"}: Donazione libera
              </Typo>
              <Typo type="p">
                <b>Ricarica PostePay</b>
                <br/>N°: 4023 6009 3490 3560  
                <br/>{lang === "it" ? "Intestato a" : "Transfer to"}: Erica Coizzi ({lang === "it" ? "presidente" : "president"})
                <br/>{lang === "it" ? "Codice fiscale" : "Tax code"}: CZZRCE79C60E897K 
              </Typo>
            </>
          }
        </Padding>
      </Section>
      
      <Section 
        variant="primary-1" 
        imageDx={"/web/sections/sostienici-volontariato.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
        <Typo type="h2" align="center">{lang === "it" ? "Volontariato in presenza" : "Volunteering"}</Typo>
          {lang === "it" ? 
            <>
              <Typo type="p">
                Tra le attività che si possono svolgere come volontari ci sono:<br/>
                <ul>
                  <li>Collaborare alla cura quotidiana dei cavalli presenti al centro (mettere fieno e acqua, spazzolarli, pure il paddock, dare eventuali integrazioni o medicine, uscire in passeggiata...).</li>
                  <li>Ideare, organizzare ed aiutare a realizzare gli eventi.</li>
                  <li>Presenziare durante gli eventi e dare una mano per portarli a termine al meglio.</li>
                  <li>Aiutare a gestire recuperi e sopralluoghi in tutta Italia.</li>
                  <li>Offrire stallo.</li>
                  <li>Offrire prestazioni professionali o competenze che possano essere utili all'associazione.</li>
                </ul>
              </Typo>
            </>
          : 
            <>
              <Typo type="p">
                Among the activities that can be carried out as a volunteer are:<br/>
                <ul>
                  <li>Helping with the daily care of the horses at the centre (putting in hay and water, brushing them, cleaning the paddock, administering any supplements or medicine, going out for walks...).</li>
                  <li>Create, organise and help with events.</li>
                  <li>Being present during events and helping to carry them out as best as possible.</li>
                  <li>Help manage retrievals and inspections throughout Italy.</li>
                  <li>Offer stalling.</li>
                  <li>Offer professional services or skills that may be useful to the association.</li>
                </ul>
              </Typo>
            </>
          }
        </Padding>
      </Section>
      
      {/*
      <Section 
        variant="primary-3" 
        imageDx={"/web/sections/sostienici-volontariatoadistanza.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
        <Typo type="h2" align="center">{lang === "it" ? "Volontariato a distanza" : "Remote volunteering"}</Typo>
          {lang === "it" ? 
            <>
              <Typo type="p">
                Tra le attività che si possono svolgere come volontari ci sono:<br/>
                <ul>
                  <li>Collaborare alla cura quotidiana dei cavalli presenti al centro (mettere fieno e acqua, spazzolarli, pure il paddock, dare eventuali integrazioni o medicine, uscire in passeggiata...).</li>
                  <li>Ideare, organizzare ed aiutare a realizzare gli eventi.</li>
                  <li>Presenziare durante gli eventi e dare una mano per portarli a termine al meglio.</li>
                  <li>Aiutare a gestire recuperi e sopralluoghi in tutta Italia.</li>
                  <li>Offrire stallo.</li>
                  <li>Offrire prestazioni professionali o competenze che possano essere utili all'associazione.</li>
                </ul>
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Come fare"
                link="/it/sostienici/volontariato-a-distanza"
                icon="arrow_forward"
              />
            </>
          : 
            <>
              <Typo type="p">
                Among the activities that can be carried out as a volunteer are:<br/>
                <ul>
                  <li>Helping with the daily care of the horses at the centre (putting in hay and water, brushing them, cleaning the paddock, administering any supplements or medicine, going out for walks...).</li>
                  <li>Create, organise and help with events.</li>
                  <li>Being present during events and helping to carry them out as best as possible.</li>
                  <li>Help manage retrievals and inspections throughout Italy.</li>
                  <li>Offer stalling.</li>
                  <li>Offer professional services or skills that may be useful to the association.</li>
                </ul>
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Come fare"
                link="/it/support-us/remote-volunteering"
                icon="arrow_forward"
              />
            </>
          }
        </Padding>
      </Section>*/}
      
      <Section variant="primary-2">
        <Padding top right bottom left>
        <Typo type="h2" align="center">{lang === "it" ? "Dona materiale" : "Donate stuff"}</Typo>
          {lang === "it" ? 
            <>
              <Typo type="p">
                Tutti i cavalli salvati vanno curati quotidianamente, quindi il materiale per farlo è indispensabile.
              </Typo>
              <Typo type="p">
                Anche tu, con un piccolo gesto, potrai aiutarci!
              </Typo>
              <Typo type="p">
                Ecco di cosa abbiamo bisogno costantemente: 
                <ul>
                  <li>fieno, sacchi di avena, integratori</li>
                  <li>secchi</li>
                  <li>materiale veterinario di base come garze, betadine, siringhe, bende, ecc...</li>
                  <li>cuffie da paddock, fasce</li>
                  <li>forche, scope, carriole</li>
                  <li>brusche, striglie, nettapiedi, spugne</li>
                </ul>
                &Egrave; per noi un prezioso aiuto!
              </Typo>
              <Typo type="p">
                Potrai portare il materiale che vuoi donarci presso la struttura dove ospitiamo i cavalli previo appuntamento telefonico oppure spedirlo.<br/>
                Contattaci pure!
                 
              </Typo>
            </>
          : 
            <>
              <Typo type="p">
              All rescued horses have to be cared for on a daily basis, so the material to do this is indispensable.
              </Typo>
              <Typo type="p">
                You too, with a small gesture, can help us!
              </Typo>
              <Typo type="p">
                Here is what we need constantly:
                <ul>
                  <li>hay, sacks of oats, supplements</li>
                  <li>buckets</li>
                  <li>basic veterinary supplies such as gauze, betadine, syringes, bandages, etc...</li>
                  <li>paddock caps, headbands</li>
                  <li>forks, brooms, wheelbarrows</li>
                  <li>brushes, groomers, sponges</li>
                </ul>
                It's a precious help to us!
              </Typo>
              <Typo type="p">
                You can bring the material you wish to donate to us to the facility where we house the horses by telephone appointment or send it to us.<br/>
                Feel free to contact us!                 
              </Typo>
            </>
          }
        </Padding>
      </Section>


      {/*<Section>
        <Typo type="h2" align="center">{lang === "it" ? "Tesseramento" : "Membership"}</Typo>
        {lang === "it" ? 
          <>
            <Typo type="p">
              TODO Il tesseramento annuale è una donazione ricorrente che ci aiuta a sostenere le spese e che ti consente di accedere a contenuti riservati ai membri!
            </Typo>
          </>
        : 
          <>
            <Typo type="p">
              TODO Il tesseramento annuale è una donazione ricorrente che ci aiuta a sostenere le spese e che ti consente di accedere a contenuti riservati ai membri!
            </Typo>
          </>
        }

        <center>
          TODO
          {/ * <a href="https://donate.stripe.com/14k14SepN0oo7nO5km" target="_blank">
            <Button size="big" variant="mint">{lang === "it" ? "Dona ora" : "Donate now"}</Button>
          </a> * /}
        </center>
      </Section>*/}
      
      {/*
      <Section 
        variant="primary-3" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">{lang === "it" ? "Candidati per un'adozione" : "Candidati per un'adozione"}</Typo>
          {lang === "it" ? 
            <>
              <Typo type="p">
                Qui sotto puoi vedere alcuni dei cavalli a cui stiamo cercando casa. Ogni cavallo ha la propria storia unica e un carattere speciale.<br/>
                Guarda la loro scheda per conoscere meglio i cavalli in cerca di una nuova famiglia. Se trovi qualcuno che cattura il tuo cuore, compila il modulo di adozione e preparati a dare il benvenuto a un nuovo membro nella tua vita.
              </Typo>
              <Typo type="p">
                Anche se nessuno dei cavalli attualmente disponibili sembra adatto a te, ti incoraggiamo comunque a compilare il modulo di adozione. I nostri volontari lavoreranno diligentemente per trovare il perfetto compagno equino basandosi sulle tue preferenze e necessità. Insieme, possiamo trovare il cavallo ideale che si adatti al tuo stile di vita e alla tua famiglia.
              </Typo>
              <Typo type="p">
                Non perdere l'opportunità di trasformare la vita di un cavallo e di arricchire la tua con la compagnia di un amico equino. 
              </Typo>
              <br/>
              <Typo type="p" align="center">              
                <Cta 
                  size="big"
                  variant="light-o"
                  text="Candidati"
                  link="/it/sostienici/candidatura-adozione"
                  icon="arrow_forward"
                />
              </Typo>
            </>
          : 
            <>
              <Typo type="p">
                Qui sotto puoi vedere alcuni dei cavalli a cui stiamo cercando casa. Ogni cavallo ha la propria storia unica e un carattere speciale.<br/>
                Guarda la loro scheda per conoscere meglio i cavalli in cerca di una nuova famiglia. Se trovi qualcuno che cattura il tuo cuore, compila il modulo di adozione e preparati a dare il benvenuto a un nuovo membro nella tua vita.
              </Typo>
              <Typo type="p">
                Anche se nessuno dei cavalli attualmente disponibili sembra adatto a te, ti incoraggiamo comunque a compilare il modulo di adozione. I nostri volontari lavoreranno diligentemente per trovare il perfetto compagno equino basandosi sulle tue preferenze e necessità. Insieme, possiamo trovare il cavallo ideale che si adatti al tuo stile di vita e alla tua famiglia.
              </Typo>
              <Typo type="p">
                Non perdere l'opportunità di trasformare la vita di un cavallo e di arricchire la tua con la compagnia di un amico equino. 
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Apply"
                link="/en/support-us/adoption-application"
                icon="arrow_forward"
              />
            </>
          }    
          
          <br/><br/>

          {horsesResident.length > 0 ? 
            <Showcase
              list={horsesSearching}
              cols={6}
            />    
          : null }
        </Padding>
      </Section>
      */}

      <Section 
        variant="primary-1" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">{lang === "it" ? "Adotta a distanza" : "Adopt at a distance"}</Typo>
          {lang === "it" ? 
            <>
              <Typo type="p">
                Ogni cavallo ci costa circa 300 euro al mese tra mantenimento, pareggi, vermifughi, spese veterinarie ordinarie, integrazioni, ecc...<br/>
                Adottando uno dei nostri cavalli a distanza, a partire da 20€ al mese, ci aiuti a sostenere tutte le sue spese.<br/>
                In questo modo diventerai parte della sua Cerchia, cioè:
                <ul>
                  <li>Il nome da te scelto comparirà tra quelli dei sostenitori sulla sua pagina.</li>
                  <li>Verrai aggiornato sulla vita del cavallo, riceverai foto e video e, qualora ne avessi la possibilità, potrai venire a trovarlo quando vorrai.</li>
                  <li>Dormirai sonni sereni sapendo che il cavallo che hai scelto ha una persona in più a sostenerlo!</li>
                </ul>
              </Typo>
            </>
          : 
            <>
              <Typo type="p">
                Each horse costs us about 300€ per month between maintenance, draws, deworming, ordinary veterinary expenses, supplements, etc...<br/>
                By adopting one of our horses at a distance, starting from 20€ per month, you help us support all its expenses.<br/>
                In this way you will become part of his circle, i.e.: 
                <ul>
                  <li>The name you choose will appear among the supporters on his page.</li>
                  <li>You will be kept up to date on the horse's life, receive photos and videos and, if you have the chance, you will be able to visit him whenever you want.</li>
                  <li>You will sleep like a baby knowing that the horse you have chosen has one more person to support it!</li>
                </ul>
              </Typo>
            </>
          }    
          
          {horsesResident.length > 0 ? 
            <Showcase
              list={horsesResident}
              cols={6}
            />    
          : null }
        </Padding>
      </Section>

{/* 
      <Section>
        <Typo type="h2" align="center">{lang === "it" ? "Fide e mezzefide" : "Fides and half-fides"}</Typo>
        <Typo type="p">
        {lang === "it" ? 
          <>
            <Typo type="p">
              Presto in arrivo!
            </Typo>
          </>
        : 
          <>
            <Typo type="p">
              Coming soon!
            </Typo>
          </>
        } 
        </Typo>
      </Section>
     <ImageFull src="/photos/helpus2.jpg" alt={lang === "it" ? "Cavallo che bruca a Equinozio ODV" : "Horse grazing at Equinozio ODV"} /> */}
    </Layout>
  )
}

export default HelpUsPage
